<template>
    <div v-if="thisStockTaking">
        <v-menu 
            v-model="menuSelect" 
            :close-on-content-click="false" 
            offset-y  offset-overflow
            rounded="xl" max-width="500" nudge-width="300"
            >
            <template v-slot:activator="{ on }"> 
                <v-card   v-on="on" class="py-1"  color="transparent"  outlined
                     min-height="20" rounded="lg" flat >
                    <v-layout fill-height align-center class="ma-0 px-2 pb-1 pt-1 mbs-one-line"> 
                        <v-icon small>mdi-account</v-icon>  
                        <div class="font-weight-bold not-f3 ">{{MBS.actions.SIZE(thisStockTaking.joined_users)}}</div>
                        <v-icon small class="ml-1">mdi-alarm</v-icon>
                        <div class="font-weight-bold not-f3 ">
                           {{LiveLocalJoinedStockTaking?LiveLocalJoinedStockTaking:'.'}}
                        </div> 
                        <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                    </v-layout> 
                </v-card> 
            </template> 

            <v-card  v-if="true"  
                color="lighten-1" class="">  
                <v-card-title class="secondary white--text" dark>
                    <v-list-item  dark> 
                        <v-list-item-content>
                            <v-list-item-title>Stock Taking</v-list-item-title>
                            <v-list-item-subtitle>{{SelectedCompany?SelectedCompany.company_name:'..'}}</v-list-item-subtitle>  
                        </v-list-item-content>
                        <v-list-item-action><v-btn icon="" @click="menuSelect=false"><v-icon>close</v-icon></v-btn></v-list-item-action>
                    </v-list-item>
                </v-card-title> 
                <v-list dense>
                    <v-list-item >
                        <v-list-item-action><v-icon>mdi-cash-register</v-icon></v-list-item-action>
                        <v-list-item-content>
                            <div>Outlet:</div> 
                        </v-list-item-content>
                        <v-list-item-action>
                            <div>{{thisStockTaking?thisStockTaking.outlet_name:'..'}}</div> 
                        </v-list-item-action>
                    </v-list-item>  
                    <v-list-item  >
                        <v-list-item-action><v-icon>mdi-timer-outline </v-icon></v-list-item-action>
                        <v-list-item-content>
                            <div>Started at:</div>  
                        </v-list-item-content>
                        <v-list-item-action>
                            <div>{{thisStockTaking.created_at_}}</div>   
                        </v-list-item-action>
                    </v-list-item>  
                    <v-list-item  >
                        <v-list-item-action><v-icon>mdi-cart</v-icon></v-list-item-action>
                        <v-list-item-content>
                            <div>All Items</div> 
                            <div>Submitted Items</div>
                            <div>Remaining Items</div> 
                            <div>Items Kits / Services</div> 
                        </v-list-item-content>
                        <v-list-item-action>
                            <div>{{MBS.actions.SIZE(ItemsData)}}</div> 
                            <div>{{MBS.actions.SIZE(StockTakingSubmittedItems)}}</div> 
                            <div>{{MBS.actions.SIZE(ItemsData)-MBS.actions.SIZE(StockTakingSubmittedItems)}}</div> 
                            <div>{{MBS.actions.SIZE(ItemKits)}}</div> 
                        </v-list-item-action>
                    </v-list-item> 

                    <!-- // USER LIST -->
                    <v-list-group>
                        <template v-slot:activator>
                            <v-list-item-action><v-icon>group</v-icon></v-list-item-action>
                            <v-list-item-content>
                                <div>{{MBS.actions.SIZE(thisStockTaking.joined_users)}} User(s) Joined </div>  
                            </v-list-item-content> 
                        </template> 
                        <div v-for="(user,index) in StockTakingUsers" :key="index" class="ml-7">
                            <v-divider v-if="index==0" ></v-divider>
                            <v-list-item dense >
                                <v-list-item-action >
                                    <v-avatar v-if="user.photoURL"
                                        size="25"  >
                                        <v-img :src="user.photoURL" lazy-src="https://picsum.photos/10/6?image=3" >
                                            <template v-slot:placeholder>
                                                <v-row
                                                    class="fill-height ma-0"
                                                    align="center"
                                                    justify="center">
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="grey lighten-5"
                                                        /> 
                                                </v-row>
                                            </template>
                                        </v-img>
                                    </v-avatar> 
                                    <v-avatar v-else
                                        size="25" 
                                        color="secondary" class="white--text" >
                                        <div v-if="user">
                                            {{MBS.actions.TEXT_UP(user.surname?user.surname.slice(0,1):'..')}} 
                                        </div>
                                    </v-avatar>   
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title >
                                        <v-avatar class="mb-1"
                                            size="10"  
                                            :color="user.online?'green':'red'" > 
                                        </v-avatar>
                                       {{user.surname}} {{user.names}}  
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{user.joined_at_}} --to-- {{user.left_at_}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn color="secondary" :disabled="user.left" @click="REMOVE_USER(user)" outlined small>Remove</v-btn>  
                                </v-list-item-action>
                            </v-list-item>   
                            <v-divider inset ></v-divider> 
                        </div> 
                    </v-list-group>  
                </v-list>  
                <v-layout row wrap align-center justify-center class="ma-2">
                    <v-menu   
                        :close-on-content-click="true" 
                        offset-y  offset-overflow
                        :nudge-width="70" rounded="xl"
                        >
                        <template v-slot:activator="{ on, attrs }">  
                            <v-btn v-on="on" class="mb-2" color="primary"> 
                                <v-icon class="mr-2">mdi-auto-fix</v-icon> Auto Zero Items <v-icon class="ml-5">mdi-menu-down</v-icon> 
                            </v-btn> 
                        </template> 

                        <v-card  v-if="ud"  
                            color="lighten-1" class="">  
                            <v-card-title class="secondary white--text py-" dark>
                                Select Action
                            </v-card-title> 
                            <v-layout class="ma-0">
                                <v-list dense>  
                                        <v-list-item :class="''" @click="AUTO_ZERO_ITEMS('remaining_items')" >
                                            <v-list-item-avatar><v-icon>mdi-tag</v-icon></v-list-item-avatar> 
                                            <v-list-item-content>Zero remaining Item(s) </v-list-item-content>
                                        </v-list-item> 
                                        <v-divider inset></v-divider>
                                        <v-list-item :class="''" @click="AUTO_ZERO_ITEMS('remaining_item_kits')" >
                                            <v-list-item-avatar><v-icon>mdi-group</v-icon></v-list-item-avatar> 
                                            <v-list-item-content>Zero remaining Item Kit(s) </v-list-item-content>
                                        </v-list-item> 
                                        <v-divider inset></v-divider>
                                </v-list>   
                            </v-layout>
                        </v-card>  
                    </v-menu>  
                    
                </v-layout>
                <v-divider></v-divider>  
                <v-card-actions class="pa-0">
                    <v-btn @click="CLOSE_STOCK_TAKING()" height="50"   width="50%" class="ma-0" tile color="primary">Close & Finish</v-btn>
                    <v-btn @click="LEAVE_STOCK_TAKING()" height="50"   width="50%" class="ma-0" tile color="secondary">Leave Stock Take</v-btn>
                </v-card-actions>
            </v-card>  
        </v-menu>    
        <div>
            <!-- CLOSE REGISTER -->
            <v-dialog
                v-model="dialog_close_stock_taking"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg">
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">CLOSE THIS STOCK TAKING</div> 
                        <v-spacer></v-spacer>
                        <!-- <div class="font-weight-bold b2--text not-f7">Expect: mwk {{MBS.actions.money(input.expected_cash)}} </div> -->
                        <v-btn dark icon class="ml-5" @click="dialog_close_stock_taking=false" ><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <br> 
                        <form @submit.prevent="CLOSE_STOCK_TAKING()">
                            <div class="ma-5" v-if="dialog_close_stock_taking">
                                <mbs-input-fields 
                                    v-model="input"
                                    :disabled_fields="DisabledCashupFields"
                                    :field_items="DATA.FIELDS.close_cashups"
                                    :select_items="SelectInputFieldsItems"  
                                    />    
                                <v-layout class="ma-0 mt-5">
                                    <v-spacer></v-spacer>
                                    <!-- <v-btn text large color="primary" @click="dialog_close_stock_taking = false">Not now</v-btn> -->
                                    <v-btn :loading="inputLoading" type="" large color="primary">Close Stock Taking</v-btn>
                                </v-layout>
                            </div> 
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-dialog> 
        </div>
    </div>
</template> 
<script>
let PAGE_NAME = 'LIVE-STOCK-TAKE'
import DATA from "../../plugins/DATA"
import DB from "../../plugins/DB"
import {mapState,mapGetters } from "vuex"  
import { mdiCreationOutline } from '@mdi/js';
export default { 
    data(){
        return{  
            dialog:false, 
            input:{}, 
            menuSelect:false,
            dialog_close_stock_taking:false, 
            DATA:DATA, 
            mdiCreationOutline:mdiCreationOutline, 
        } 
    }, 
    created() {
        try {
            this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);   
            this.MBS.events.$on('ON_QR_SCAN', this.ON_QR_SCAN);   
            this.MBS.events.$on('LIVE_STOCK_TAKE_REFRESH_ITEMS', this.LIVE_STOCK_TAKE_REFRESH_ITEMS);   
            this.MBS.events.$on('LEAVE_STOCK_TAKING', this.LEAVE_STOCK_TAKING);   
            this.MBS.events.$on('CLOSE_STOCK_TAKING', this.CLOSE_STOCK_TAKING);   
            // this.MBS.events.$on('ITEM_ACTION', this.ITEM_ACTION);   
            
        } catch (error) {
            this.MBS.actions.error({
                error:error,
                from:'created',
                page:PAGE_NAME, 
            }) 
        }
    },
    mounted(){ 
        this.input={}
        this.inputLoading=false   
    },
    computed:{
        route(){
            return this.$route
        },
        vs(){
            return this.$vuetify.breakpoint
        }, 
        vsh(){
            return this.vs.height
        },
        ...mapState({
            loading:state=>state.load.loading,
            processes:state=>state.load.processes,
            responses:state=>state.load.responses,
        }),
        ...mapState({
            us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
            ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
        }),
        ...mapState({
            CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
            Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
            JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()],  
            
            StockTakings: state=> state.items[(DATA.ITEMS.STOCK_TAKINGS.values).toUpperCase()],
            JoinedStockTakings: state=> state.join[(DATA.ITEMS.STOCK_TAKINGS.values).toUpperCase()], 
            StockTakingOrders: state=> state.items[(DATA.ITEMS.STOCK_TAKING_ORDERS.values).toUpperCase()],
            JoinedStockTakingOrders: state=> state.join[(DATA.ITEMS.STOCK_TAKING_ORDERS.values).toUpperCase()], 
            LocalJoinedStockTaking: state=> state.items[(DATA.ITEMS.LOCAL_JOINED_STOCK_TAKING.values).toUpperCase()],
            SelectedCompany: state=> state.items["SELECTED_COMPANY"],  
        }), 
        ItemsData(){  
            let items = this.JoinedItems?this.JoinedItems:this.Items  
            return items
        }, 
        
        ItemKits(){  
            let items = this.ItemsData  
            if(!items){return null}
            let filtered = items.filter(item=>item.item_kits)  
            return filtered
        }, 

        StockTakingsData(){  
            let items = this.JoinedStockTakings?this.JoinedStockTakings:this.StockTakings  
            return items
        }, 
        StockTakingOrdersData(){  
            let items = this.JoinedStockTakingOrders?this.JoinedStockTakingOrders:this.StockTakingOrders  
            return items
        }, 
        thisStockTaking(){  
            let StockTakings = this.StockTakingOrdersData
            let LocalJoinedStockTaking = this.LocalJoinedStockTaking  
            if(!LocalJoinedStockTaking||!StockTakings){return null}
            let item = StockTakings.find(stock=>{
                return stock.key == LocalJoinedStockTaking.key
            })   
            return item
        },  
        StockTakingUsers(){  
            let StockTakings = this.thisStockTaking 
            if(!StockTakings){return null}
            let users_obj = StockTakings.users
            let user_list = this.MBS.actions.OBJECT_ITEMS(users_obj) 
            if(!user_list){return null}
            let users = []
            user_list.forEach(us => {
                let usd = this.$store.getters.getUserByUid(us.uid)
                if (usd) {
                    users.push({
                        ...usd,
                        ...us,
                        joined_at_:this.MBS.actions.toDateTime(us.joined_at),
                        left_at_:us.left?this.MBS.actions.toDateTime(us.left_at):"Running.."
                    })
                }else{
                    users.push({...us})
                }
            }); 
            users = this.MBS.actions.JOIN_USERS(users)
            console.log(users);
            return users
        },  
        StockTakingSubmittedItems(){  
            let stockTakings = this.thisStockTaking
            if(!stockTakings){return null}
            return this.MBS.actions.OBJECT_ITEMS(stockTakings.items) 
        }, 

        //ITEMS  
        ItemsSubmitted(){
            const items = this.StockTakingSubmittedItems 
            if(!items){ return null}
            let all = [] 
            items.forEach(items => {
                all.push({
                    ...items,
                    new_quantity:this.toNumber(items.new_quantity),
                    uploaded:true
                })
            });   
            return all
        },
        ItemsOnLoading(){
            const items = this.LocalStockTakingItems 
            if(!items){ return null}   
            return items
        },
        ItemsCounted(){
            const submitted = this.ItemsSubmitted
            const onLoading = this.ItemsOnLoading 
            let all = []

            if (onLoading) {
                onLoading.forEach(items => {
                    all.push({
                        ...items,
                        new_quantity:this.toNumber(items.new_quantity),
                    })
                });  
            }

            if (submitted) { 
                submitted.forEach(items => {
                    all.push({
                        ...items,
                        new_quantity:this.toNumber(items.new_quantity),
                        uploaded:true
                    })
                }); 
            } 
            return all
        },
        ItemsNotSubmitted(){
            const ItemsSubmitted = this.ItemsSubmitted 
            const items = this.ItemsData 
            if(!items){ return null}
            let all = [] 
            items.forEach(item => {
                let submitted = (key)=>{
                    if(!ItemsSubmitted){return null}
                    return ItemsSubmitted.find(item=>item.key==key)
                }
                if (!submitted(item.key)) {
                    all.push({
                        ...item,
                        new_quantity:this.toNumber(item.new_quantity),
                        not_submitted:true
                    }) 
                }
            });   
            return all
        }, 
        ItemsNotCounted(){
            const ItemsCounted = this.ItemsCounted 
            const items = this.ItemsData 
            if(!items){ return null}
            let all = [] 
            items.forEach(item => {
                let counted = (key)=>{
                    if(!ItemsCounted){return null}
                    return ItemsCounted.find(item=>item.key==key)
                }
                if (!counted(item.key)) {
                    all.push({
                        ...item,
                        new_quantity:this.toNumber(item.new_quantity),
                        item_not_counted:true
                    }) 
                }
            });   
            return all
        }, 

        SubmittedAndNot(){
            let submitted = this.StockTakingSubmittedItems
            let unSubmitted = this.LocalStockTakingItems 
            let all = []

            if (unSubmitted) {
                unSubmitted.forEach(items => {
                    all.push(items)
                });  
            }

            if (submitted) {
                submitted.forEach(items => {
                    all.push({
                        ...items,
                        uploaded:true
                    })
                }); 
            }
            
            return all
        },
        percentageProgress(){
            let submitted = this.SubmittedAndNot
            let items = this.ItemsData 
            let sizeSubmitted = this.MBS.actions.SIZE(submitted)
            let sizeItems = this.MBS.actions.SIZE(items) 
            return sizeSubmitted*100/sizeItems
        }, 
        liveTime(){
            let time = this.MBS.date.live 
            return time
        },
        LiveLocalJoinedStockTaking(){  
            let stockTaking = this.thisStockTaking 
            let live = this.liveTime
            let moment = this.MBS.date.moment
            let created_at = stockTaking?stockTaking.created_at:null 
            let momentDate = moment(created_at) 
            if (created_at) {
                let diff_hours = moment(live).diff(created_at,'hours')
                let diff_minute = moment(live).diff(created_at,'minutes')
                let diff_seconds = moment(live).diff(created_at,'seconds')
                let sec = diff_seconds - (diff_minute*60)
                let min = diff_minute - (diff_hours*60)
                return ''+diff_hours+'h '+min+'m '+sec+'s'
            } 
            return moment(created_at).format('yyyy-MM-D .')
        },  
        SelectInputFieldsItems(){  
            let Tills = this.DayShiftsTills  
            return { 
                Tills:Tills,  
            }
        },  
    },
    methods:{  
        TOTAL_AMOUNT(items){
            try {  
                let total_amount = 0 
                if (!items) {return 0}
                items.forEach(item => {
                    let amount = this.toNumber(item.amount) 
                    total_amount = total_amount + amount
                });  
                return total_amount
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'TOTAL_AMOUNT',
                    page:PAGE_NAME, 
                }) 
                return 0
            }  
        },  
        UPLOADED(items){
            try { 
                if(!items){return null}
                return items.filter(item=>{
                    return item.uploaded
                })  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'UPLOADED',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        UN_UPLOADED(items){
            try { 
                if(!items){return null}
                return items.filter(item=>{
                    return !item.uploaded
                })  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'UPLOADED',
                    page:PAGE_NAME, 
                }) 
            } 
        }, 
        TOTAL_SOLD(items){
            try {  
                let total = 0
                if (items) {
                    items.forEach(element => {
                        let sold_price = this.toNumber(element.sold_price)
                        let quantity = this.toNumber(element.quantity)
                        let discount = this.toNumber(element.discount)
                        total = total+((sold_price-discount)*quantity)
                    }); 
                }
                return total
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'TOTAL_SOLD',
                    page:PAGE_NAME, 
                })
            }  
        },  
        
        async CLOSE_STOCK_TAKING(){
            try {   
                let users = this.StockTakingUsers 
                let other_users = this.MBS.actions.SIZE(users)>1?users.filter(user=>user.uid!=this.us.uid):null
                let not_left_users = other_users?other_users.filter(user=>!user.left):null

                if (this.MBS.actions.SIZE(not_left_users)) {
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:"HAS JOINED USER(S)",
                        text:"This stock take has other joined user(s). To close, other user(s) must leave or be removed.",
                        btnYes:"OKAY",  
                    }) 
                }else{
                    let mbs_text = this.MBS.text.item_action("Stock Taking","Close")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=CLOSE-STOCK-TAKING", 
                        }
                    })  
                }
                
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CLOSE_STOCK_TAKING',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        async LEAVE_STOCK_TAKING(){
            try {    
                let stockTaking = this.thisStockTaking
                if (!stockTaking) {
                    console.log('error,,,',stockTaking);
                    return
                } 
                let moment = this.MBS.date.moment
                let left_at = moment?moment().valueOf():null 
                let details={
                    stock_taking_order_key:stockTaking.key, 
                    left:true,
                    left_at:left_at,
                    uid:this.us.uid
                }
                let mbs_text = this.MBS.text.item_action("Stock Taking","Leave")
                this.MBS.actions.dialog({
                    show:true,
                    fixed:true,
                    title:mbs_text.title,
                    text:mbs_text.text,
                    btnYes:mbs_text.btnYes,
                    btnNo:mbs_text.btnNo,
                    action:{
                        code:PAGE_NAME+"=LEAVE-STOCK-TAKING", 
                        data:details
                    }
                }) 
                
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'LEAVE_STOCK_TAKING',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        async LIVE_STOCK_TAKE_REFRESH_ITEMS(){
            try {  
                let mbs_text = this.MBS.text.item_action("Items","Refresh")
                this.MBS.actions.dialog({
                    show:true,
                    fixed:true,
                    title:mbs_text.title,
                    text:mbs_text.text,
                    btnYes:mbs_text.btnYes,
                    btnNo:mbs_text.btnNo,
                    action:{
                        code:PAGE_NAME+"=REFRESH-ITEMS",  
                    }
                })  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'LIVE_STOCK_TAKE_REFRESH_ITEMS',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        async REMOVE_USER(user){
            try {   
                if (!user) {
                    console.log('error,,,',user);
                    return
                } 
                let stockTaking = this.thisStockTaking
                if (!stockTaking) {
                    console.log('error,,,',stockTaking);
                    return
                }
                let moment = this.MBS.date.moment
                let left_at = moment?moment().valueOf():null 
                let details={
                    stock_taking_order_key:stockTaking.key, 
                    left:true,
                    left_at:left_at,
                    uid:user.uid,
                    removed_by:this.us.uid,
                }
                let mbs_text = this.MBS.text.item_action("User","Remove")
                this.MBS.actions.dialog({
                    show:true,
                    fixed:true,
                    title:mbs_text.title,
                    text:mbs_text.text,
                    btnYes:mbs_text.btnYes,
                    btnNo:mbs_text.btnNo,
                    action:{
                        code:PAGE_NAME+"=REMOVE-USER", 
                        data:details
                    }
                }) 
                
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'REMOVE_USER',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        async SUBMIT_STOCK(){
            try {    
                let us = this.us
                let uid = us?us.uid:null
                let stock_items = this.StockTakingSubmittedItems 
                let stock_taking = this.thisStockTaking
                let outlet_code = stock_taking?stock_taking.outlet_code:null
                let moment = this.MBS.date.moment
                let closed_at = moment?moment().valueOf():null 
                if (stock_items&&outlet_code) {
                    let stock_take_path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.stock_taking_orders+stock_taking.key,true)
                    this.inputLoading = true 
                    this.MBS.actions.progressDialog({
                        show:true,
                        fixed:false,
                        title:"Updating....."
                    })  
                    // return
                    let upload_item_data=[]
                    let upload_outlet_data=[]
                    let index = 0

                    let without_kits = stock_items.filter(it=>!it.item_kits)
                    let with_kits = stock_items.filter(it=>it.item_kits)
                    let updated_with_kits = without_kits?without_kits:[]
                    if (with_kits) {
                        with_kits.forEach(item_kit => {
                            item_kit.item_kits.forEach(kit => {
                                let kit_quantity = item_kit.new_quantity*kit.item_kit_quantity
                                let index = updated_with_kits.findIndex(it=>it.key == kit.item_key)
                                if (index!=-1) {
                                    updated_with_kits[index].new_quantity = this.toNumber(updated_with_kits[index].new_quantity)+kit_quantity
                                    updated_with_kits[index].added_kit_quantity = this.toNumber(updated_with_kits[index].added_kit_quantity)+kit_quantity

                                }else{
                                    let store_item = this.$store.getters.getItemByKey(kit.item_key)
                                    if (store_item) {
                                        updated_with_kits.push({
                                            key:store_item.key,
                                            code:store_item.code?store_item.code:'',
                                            name:store_item.name, 
                                            cost_price:store_item.cost_price?store_item.cost_price:0,
                                            wholesale_price:store_item.wholesale_price?store_item.wholesale_price:0,
                                            retail_price:store_item.retail_price?store_item.retail_price:0,
                                            new_quantity:store_item.new_quantity?store_item.new_quantity:0,
                                            quantity:store_item.quantity?store_item.quantity:0,

                                            number_opening_stocks:store_item.number_opening_stocks?store_item.number_opening_stocks:0,
                                            number_stock_receives:store_item.number_stock_receives?store_item.number_stock_receives:0,
                                            number_stock_transfers:store_item.number_stock_transfers?store_item.number_stock_transfers:0,
                                            number_stocks_returns:store_item.number_stocks_returns?store_item.number_stocks_returns:0,
                                            number_stock_adjustments:store_item.number_stock_adjustments?store_item.number_stock_adjustments:0,
                                            number_sales:store_item.number_sales?store_item.number_sales:0,
                                            number_quantity:kit_quantity, 
                                            added_kit_quantity:kit_quantity, 

                                        })
                                    }

                                }
                            }); 
                        });
                        updated_with_kits.push(...with_kits)
                    }

                    console.log(stock_items,'stock_items....');
                    console.log(without_kits,'without_kits....');
                    console.log(with_kits,'with_kits....');
                    console.log(updated_with_kits,'updated_with_kits....');

                    // for (const item of stock_items) {
                    for (const item of updated_with_kits) {
                        index++
                        let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.items+item.key,true)
                        
                        //calculate outlets
                        let store_item = this.$store.getters.getItemByKey(item.key)
                        let outlets = store_item?store_item.outlets:null

                        let number_sales = 0
                        let number_returned_sales = 0
                        let number_stock_receives = 0
                        let number_stocks_returns = 0
                        let number_stock_adjustments = 0
                        let number_stock_transfers = 0
                        let number_opening_stocks = 0
                        let number_closing_stocks = 0
                        let number_closing_sales = 0
                        
                        let outlet_sales = 0
                        let outlet_returned_sales = 0
                        let outlet_stock_receives = 0
                        let outlet_stocks_return = 0
                        let outlet_stock_adjustments = 0
                        let outlet_stock_transfer = 0
                        let outlet_opening_stocks = 0
                        let outlet_closing_stocks = 0
                        let outlet_closing_sales = 0
                     
                        if (outlets) {
                            for (const key in outlets) {
                                if (Object.hasOwnProperty.call(outlets, key)) {
                                    const outlet = outlets[key];
                                    // console.log(outlet,'outlet.......');
                                    // console.log(outlet_code,'outlet_code.......');
                                    // console.log(key,'key.......');
                                    // console.log(this.toNumber(outlet.number_opening_stocks),'xxxxxxxx.......');
                                    
                                    if (outlet_code==key) { 
                                        outlet_sales = number_sales+this.toNumber(outlet.number_sales)  
                                        outlet_returned_sales = this.toNumber(number_returned_sales)+this.toNumber(outlet.number_returned_sales)  
                                        outlet_stock_receives = this.toNumber(number_stock_receives)+this.toNumber(outlet.number_stock_receives)
                                        outlet_stocks_return = this.toNumber(number_stocks_returns)+this.toNumber(outlet.number_stocks_returns)
                                        outlet_stock_adjustments = this.toNumber(number_stock_adjustments)+this.toNumber(outlet.number_stock_adjustments)
                                        outlet_stock_transfer = this.toNumber(number_stock_transfers)+this.toNumber(outlet.number_stock_transfers)
                                        outlet_opening_stocks = this.toNumber(number_opening_stocks)+this.toNumber(outlet.number_opening_stocks)
                                        outlet_closing_stocks = 
                                            outlet_stock_receives 
                                            +outlet_opening_stocks
                                            -outlet_stocks_return
                                            -outlet_sales
                                            +outlet_returned_sales
                                            +outlet_stock_adjustments
                                        outlet_closing_sales = this.toNumber(number_closing_sales)+this.toNumber(outlet.number_closing_sales)
                                    }else{
                                        number_sales = number_sales+this.toNumber(outlet.number_sales)  
                                        number_returned_sales = this.toNumber(number_returned_sales)+this.toNumber(outlet.number_returned_sales)  
                                        number_stock_receives = this.toNumber(number_stock_receives)+this.toNumber(outlet.number_stock_receives)
                                        number_stocks_returns = this.toNumber(number_stocks_returns)+this.toNumber(outlet.number_stocks_returns)
                                        number_stock_adjustments = this.toNumber(number_stock_adjustments)+this.toNumber(outlet.number_stock_adjustments)
                                        number_stock_transfers = this.toNumber(number_stock_transfers)+this.toNumber(outlet.number_stock_transfers)
                                        number_opening_stocks = this.toNumber(number_opening_stocks)+this.toNumber(outlet.number_opening_stocks)
                                        number_closing_stocks = this.toNumber(number_closing_stocks)+this.toNumber(outlet.number_closing_stocks)
                                        number_closing_sales = this.toNumber(number_closing_sales)+this.toNumber(outlet.number_closing_sales)

                                        
                                    }
                                }
                            }
                            
                        }

                        console.log(this.toNumber(item.new_quantity),'-----------------***------------',item.new_quantity);

                        let outlet_data = { 
                            path:path+"/outlets/"+outlet_code,
                            number_sales:0,
                            number_returned_sales:0,
                            number_stock_receives:0,
                            number_stocks_returns:0,
                            number_stock_adjustments:0,
                            number_stock_transfers:0,
                            number_closing_stocks:this.toNumber(outlet_closing_stocks), 
                            number_opening_stocks:this.toNumber(item.new_quantity), 
                            number_closing_sales:this.toNumber(outlet_closing_sales), 
                            opening_stock:null,
                            opening_stocks:null,
                        }

                        let item_data = {
                            // key:item.key,
                            name:this.MBS.actions.TEXT_UP(item.name),
                            path:path,

                            number_sales:this.toNumber(number_sales)+this.toNumber(outlet_data.number_sales), 
                            number_returned_sales:this.toNumber(number_returned_sales)+this.toNumber(outlet_data.number_returned_sales), 
                            number_stock_receives:this.toNumber(number_stock_receives)+this.toNumber(outlet_data.number_stock_receives), 
                            number_stocks_returns:this.toNumber(number_stocks_returns)+this.toNumber(outlet_data.number_stocks_returns), 
                            number_stock_adjustments:this.toNumber(number_stock_adjustments)+this.toNumber(outlet_data.number_stock_adjustments), 
                            number_stock_transfers:this.toNumber(number_stock_transfers)+this.toNumber(outlet_data.number_stock_transfers), 
                            number_opening_stocks:this.toNumber(number_opening_stocks)+this.toNumber(outlet_data.number_opening_stocks), 
                            
                            number_closing_stocks:this.toNumber(item.number_quantity), 
                            number_closing_sales:this.toNumber(item.number_sales), 
                            number_average_cost:null, 
                            opening_stock:null,
                            opening_stocks:null,
                            // outlets:null,
                        }
                        
                        
                        upload_outlet_data.push(outlet_data)
                        upload_item_data.push(item_data) 
                    }  

                     
                    console.log(upload_outlet_data,'upload_outlet_data...');
                    console.log(upload_item_data,'upload_item_data...');
                    
                    // this.inputLoading = false
                    // return
                     
                    
                    //UPLOAD ITEMS
                    let item_res = await this.$store.dispatch("fi_update",{
                        multiple:true,
                        name:DATA.ITEMS.ITEMS.values,
                        action:DATA.ITEMS.ITEMS.values,
                        data:upload_item_data, 
                    }) 
                    console.log(item_res,'item_res.....******');

                    
                    //UPLOAD OUTLETS
                    let outlet_res = await this.$store.dispatch("fi_update",{
                        multiple:true, 
                        name:DATA.ITEMS.ITEMS.values,
                        action:DATA.ITEMS.ITEMS.values,
                        data:upload_outlet_data, 
                    }) 
                    console.log(outlet_res,'outlet_res.....******');

                    //CLOSE STOCK TAKE
                    await this.$store.dispatch("fi_update",{
                        path:stock_take_path,
                        name:DATA.ITEMS.STOCK_TAKING_ORDERS.values,
                        action:DATA.ITEMS.STOCK_TAKING_ORDERS.values,
                        data:{
                            closed:true,
                            closed_uid:uid,
                            closed_at:closed_at,
                        },
                        us:us,

                    }) 

                    this.inputLoading = false 
                    this.MBS.actions.progressDialog()   
                    
                    let mbs_text = this.MBS.text.item_action_success("Stock Taking","Uploading")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo, 
                    })
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'SUBMIT_STOCK',
                    page:PAGE_NAME, 
                }) 
            } 
        }, 
        UPLOAD_SALES(data){
            return new Promise(async (resolve,reject)=>{
                try {    
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Uploading Sales...."
                    }) 
                    this.$store.dispatch("fi_uploadSales")
                    .then(async res=>{   
                        this.MBS.actions.progressDialog() 
                        this.SUBMIT_CLOSE_CASHUP(data)
                    }).catch(error=>{
                       console.log(error,'error.......');
                       this.MBS.actions.progressDialog() 
                       let mbs_text = this.MBS.text.item_action_fail("Sales","Uploading")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })
                    })  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPLOAD_SALES',
                        page:PAGE_NAME, 
                    }) 
                }  
            })
        }, 

        RELOAD_ITEMS(){
            try {
                let company = this.SelectedCompany
                let company_key = company?company.company_key:null
                this.MBS.actions.progressDialog({
                    show:true,
                    text:"Refresh..."
                })
                this.$store.dispatch('fi_get_item',{
                    name:DATA.ITEMS.ITEMS.values,
                    on:false, 
                    cash:true, 
                    get:false, 
                    company_key:company_key, 
                }).then(res=>{
                    console.log(res,'res.......');
                    this.MBS.actions.progressDialog()
                    let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.ITEMS.values,"Refreshing")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo, 
                    })
                }).catch(error=>{
                    console.log(error,'error.......');
                    this.MBS.actions.progressDialog()
                    let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.ITEMS.values,"Refreshing")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo, 
                    })
                })
            } catch (error) {
                
            } 
        },
      
        async SELECT_COMPANY(company){
            try {
                let route = this.route
                let fullPath = route?route.fullPath:null
                let params = route?route.params:null
                let id = params?params.id:null  
                await this.MBS.actions.SELECT_COMPANY(company)
                if (id) {
                    // let mainPath = fullPath.split(id).pop()
                    let newPath = fullPath.replace(id,company.company_key)
                    this.MBS.actions.go(newPath)
                }
                this.menuSelect = false

            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'SELECT_COMPANY',
                    page:PAGE_NAME, 
                })
            }  
        },
        AUTO_ZERO_ITEMS(auto_action){
            try {
                this.MBS.events.$emit("AUTO_ZERO_ITEMS",null,auto_action)
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'AUTO_ZERO_ITEMS',
                    page:PAGE_NAME, 
                })
            }  
        },  

        //-------------[main]
        DIALOG_YES(action){ 
            try {
                if (action.code) {
                    if (action.code ===PAGE_NAME+'=CONFIRM-SELECT-COMPANY') {
                        if (action.YES) {
                            setTimeout(() => { 
                                this.input.check = true 
                                this.MBS.actions.dialogInput({
                                    show:true,
                                    fixed:true,
                                    title:"CONFIRM",
                                    text:"Enter your password to confirm switching to "+this.MBS.actions.TEXT_UP(action.data.company_name)+".",
                                    btnYes:"CONFIRM",
                                    field:[
                                        {field:"password",label:'Password',type:'password'}
                                    ],
                                    action:{
                                        ...action,
                                        code:PAGE_NAME+"=SELECT-COMPANY",
                                    }
                                })  
                            }, this.MBS.data.ACTION_REFRESH_TIME); 
                        } else {
                            
                        }  
                    } 
                    if (action.code ===PAGE_NAME+'=SELECT-COMPANY') {
                        if (action.YES) {
                            setTimeout(() => { 
                                let password = action.password
                                password = password?this.MBS.crypt.encrypt(password):null 
                                if (!password) { 
                                    this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                }else if (password!==this.ud.p_uid) { 
                                    this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                } else {
                                    this.SELECT_COMPANY(action.data) 
                                }   
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else {
                            
                        }
                    }  
                    if (action.code ===PAGE_NAME+'=CLOSE-STOCK-TAKING') {
                        if (action.YES) {
                            this.SUBMIT_STOCK()
                        } else { 
                        }
                    }  
                    if (action.code ===PAGE_NAME+'=LEAVE-STOCK-TAKING') {
                        if (action.YES) {
                            setTimeout(() => {
                                let stock_taking_order_key = action.data?action.data.stock_taking_order_key:null
                                let uid = action.data?action.data.uid:null 
                                this.inputLoading = true 
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating"
                                })  
                                this.$store.dispatch("fi_update",{
                                    action:DATA.ITEMS.STOCK_TAKINGS.values,
                                    path:this.MBS.actions.COMPANY_LINK(DATA.PATHS.stock_taking_orders+stock_taking_order_key+"/users/"+uid,true),
                                    data:action.data,
                                    us:this.us
                                }).then(res=>{ 
                                    let key = res?res.data?res.data.key:null:null 
                                    return DB.deleteItems({
                                        name:DATA.ITEMS.LOCAL_JOINED_STOCK_TAKING.values, 
                                    }) 
                                }).then(res=>{  
                                    this.MBS.events.$emit("SUBMIT_JOINED_STOCK_TAKING",PAGE_NAME)
                                    this.inputLoading = false
                                    this.MBS.actions.progressDialog() 
                                    let mbs_text = this.MBS.text.item_action_success("Stock Taking","leaving")
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:mbs_text.title,
                                        text:mbs_text.text,
                                        btnYes:mbs_text.btnYes,
                                        btnNo:mbs_text.btnNo, 
                                    })
                                }) .catch(error=>{ 
                                    this.MBS.actions.error({
                                        error:error,
                                        from:'JOIN-STOCK-TAKING',
                                        page:PAGE_NAME, 
                                    })  
                                    this.inputLoading = false
                                    this.MBS.actions.progressDialog() 
                                    let mbs_text = this.MBS.text.item_action_fail("Stock Taking","leaving")
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:mbs_text.title,
                                        text:mbs_text.text,
                                        btnYes:mbs_text.btnYes, 
                                    }) 
                                })
                            }, this.MBS.data.ACTION_REFRESH_TIME);   
                        } else {
                            
                        }
                    } 
                    if (action.code ===PAGE_NAME+'=REMOVE-USER') {
                        if (action.YES) {
                            setTimeout(() => {
                                let stock_taking_order_key = action.data?action.data.stock_taking_order_key:null
                                let uid = action.data?action.data.uid:null 
                                this.inputLoading = true 
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating"
                                })  
                                this.$store.dispatch("fi_update",{
                                    action:DATA.ITEMS.STOCK_TAKINGS.values,
                                    path:this.MBS.actions.COMPANY_LINK(DATA.PATHS.stock_taking_orders+stock_taking_order_key+"/users/"+uid,true),
                                    data:action.data,
                                    us:this.us
                                }).then(res=>{  
                                    this.MBS.events.$emit("SUBMIT_JOINED_STOCK_TAKING",PAGE_NAME)
                                    this.inputLoading = false
                                    this.MBS.actions.progressDialog() 
                                    let mbs_text = this.MBS.text.item_action_success("User","Removing")
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:mbs_text.title,
                                        text:mbs_text.text,
                                        btnYes:mbs_text.btnYes,
                                        btnNo:mbs_text.btnNo, 
                                    })
                                }) .catch(error=>{ 
                                    this.MBS.actions.error({
                                        error:error,
                                        from:'JOIN-STOCK-TAKING',
                                        page:PAGE_NAME, 
                                    })  
                                    this.inputLoading = false
                                    this.MBS.actions.progressDialog() 
                                    let mbs_text = this.MBS.text.item_action_fail("User","Removing")
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:mbs_text.title,
                                        text:mbs_text.text,
                                        btnYes:mbs_text.btnYes, 
                                    }) 
                                })
                            }, this.MBS.data.ACTION_REFRESH_TIME);   
                        } else {
                            
                        }
                    }  
                    if (action.code ===PAGE_NAME+'=REFRESH-ITEMS') {
                        if (action.YES) {
                            setTimeout(() => {
                                this.RELOAD_ITEMS() 
                            }, this.MBS.data.ACTION_REFRESH_TIME);   
                        } else {
                            
                        }
                    }   
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'DIALOG_YES',
                    page:PAGE_NAME, 
                }) 
            }  
        },
        ON_QR_SCAN(code){
                try {
                    if (code) {  
                        let size = this.MBS.actions.SIZE(code)
                        if (size<3) { 
                        }else{
                            let first = code.slice(0,1)
                            let last = code.slice(-1)
                            let mid = code.slice(1,-1)


                            if (first=="%" && last=="?") {
                                code = mid
                                this.DESELECT_CARD()
                                this.SELECT_CARD(null,code)  
                                console.log(code,'c///////////..........');
                            } else { 
                                // this.ADD_ITEM(null,(code)) 
                            }  
                            
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_QR_SCAN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
    },
    beforeDestroy(){
        this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);    
        this.MBS.events.$off('ON_QR_SCAN', this.ON_QR_SCAN);    
        this.MBS.events.$off('LIVE_STOCK_TAKE_REFRESH_ITEMS', this.LIVE_STOCK_TAKE_REFRESH_ITEMS);    
        this.MBS.events.$off('LEAVE_STOCK_TAKING', this.LEAVE_STOCK_TAKING);    
        this.MBS.events.$off('CLOSE_STOCK_TAKING', this.CLOSE_STOCK_TAKING);    
    },
    watch:{ 
        MyCompanies(value){  
        },
        route(value){ 
        }, 
        input(){
             
        }
        
    }
}
</script> 